<template>
  <div>
    <topBoxs></topBoxs>
    <div style="padding: 20px 10px 0px 30px;">
      <p>1、请务必阅读每项服务的完整说明。</p>
      <p>2、同一链接禁止在订单状态未显示完成之前再次下单。等待第一个订单完成，然后为同一链接下新订单。</p>
      <p>3、同一个链接同时购买多个拉人上粉项目数量不会叠加且不退款</p>
      <p>4、下单后订单未完成禁止更改链接 更改链接=订单完成</p>
      <p>5、订单如果出现延迟请耐心等待</p>
    </div>
    <div style="margin-top: 30px;">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="12"  :lg="12" :xl="12">
          <el-card class="box-cardThreeList" shadow="always">
            <el-form :model="ruleForms" :rules="rules" ref="ruleForms" label-width="90px" class="demo-ruleForm">
              <el-form-item label="服务类别" prop="name">
                <el-select v-model="ruleForms.name" placeholder="点击搜索服务类别" :popper-append-to-body="false" style="width: 100%;" @change="getList">
                  <el-option v-for="(item,index) in listData" :key="index" :value="item.value" :label="item.label"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="服务项目" prop="region">
                <el-select v-model="ruleForms.region" placeholder="点击搜索服务项目" :popper-append-to-body="false" style="width: 100%;" @change="getPrice">
                  <el-option v-for="(item,index) in tableData" :key="index" :value="item.id" :label="item.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="描述" prop="desc">
                <el-input type="textarea" v-model="ruleForms.desc" placeholder="描述" :rows="10"></el-input>
              </el-form-item>
              <el-form-item label="账号/链接" prop="code">
                <el-input v-model="ruleForms.code" placeholder="填写要刷的链接或账号"></el-input>
              </el-form-item>
              <el-form-item label="单价" prop="price">
                <el-input v-model="ruleForms.price" disabled>
                  <template slot="append">usdt</template>
                </el-input>
              </el-form-item>
              <el-form-item label="数量" prop="number">
                <el-input-number v-model="ruleForms.number" @change="handleChange" :min="1" :max="10"></el-input-number>
              </el-form-item>
              <el-form-item label="单价" prop="priceTotal">
                <el-input v-model="ruleForms.priceTotal" disabled>
                  <template slot="append">usdt</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button style="width: 100%" type="danger" @click="submitForm('ruleForms')">下单</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12"  :lg="12" :xl="12">
          <el-card class="box-cardThreeList" shadow="always">
            <div style="margin-bottom: 20px;">
              <el-button type="warning" size="small">服务实时更新</el-button>
            </div>
            <div class="contListBoxs">
              <span style="padding: 5px 8px;font-size: 13px;color: #E47470;background: #291E1E;border-radius:5px;">今日新增服务</span>
              <p>Twitter 转推 [大v] 24小时转推+1个超级帐户赞</p>
              <p>Twitter 转推 [大v] 24小时转推+5个超级帐户赞</p>
              <p>Twitter 转推 [大v] 24小时转推+10个超级帐户赞</p>
              <p>Twitter 转推 [大v] 24小时转推+20个超级帐户赞</p>
              <p>Twitter 转推 [大v] 24小时转推+30个超级帐户赞</p>
            </div>
            <div class="contListBoxs" style="margin-top: 20px;">
              <span style="padding: 5px 8px;font-size: 13px;color: #E47470;background: #291E1E;border-radius:5px;">今日新增僵尸服务</span>
              <p>电报成员 [0掉率] [中文名称] [超级快速]</p>
              <p>电报会员 [0掉率] 启动快</p>
              <p>电报成员[英文名称] [自动补充30天] 快速</p>
              <p>电报会员[0掉率][英文名称]</p>
              <p>电报会员 [0掉率][混合名称]</p>
            </div>
            <div class="contListBoxs" style="margin-top: 20px;">
              <span style="padding: 5px 8px;font-size: 13px;color: #E47470;background: #291E1E;border-radius:5px;">今日新增在线僵尸服</span>
              <p>电报会员 在线僵尸（一个月）</p>
            </div>
          </el-card>
        </el-col>
      </el-row>

    </div>
  </div>
</template>
<script>
import topBoxs from "@/views/loggedInMall/components/topBoxs.vue";
export default {
  components: {
    topBoxs
  },
  data() {
    return {
      ruleForms:{
        price:0,
        number:1,
        priceTotal:0,
        desc:"1：要刷更大数量的请选择下单份数即可；作品链接下单（分享作品-复制链接），填写口令会导致自动退单！\n\n2：作品不能设置私密.刚发布的作品请等审核通过后30分钟再下单.避免作品被审核删除情况！\n\n3：到账时间：根据单天情况进行订单，下单了请耐心等待！正常秒到"
      },
      rules:{
        name: [
          { required: true, message: '请选择服务类别', trigger: 'change' }
        ],
        region: [
          { required: true, message: '请选择服务项目', trigger: 'change' }
        ],
        code:[
          { required: true, message: '请输入账号/链接', trigger: 'blur' }
        ]
      },
      listData:[
        {
          label:'快手播放',
          value:1,
          children:[
            {
              "id": 51,
              "name": "快手播放1000次",
              "price": "0.01",
              "sfcategory_id": 22,
              "deletetime": null
            },
            {
              "id": 52,
              "name": "快手播放10000次",
              "price": "0.05",
              "sfcategory_id": 22,
              "deletetime": null
            },
            {
              "id": 53,
              "name": "快手播放十万次",
              "price": "0.45",
              "sfcategory_id": 22,
              "deletetime": null
            }
          ]
        },
        {
          label:'快手关注',
          value:2,
          children:  [
            {
              "id": 48,
              "name": "快手粉丝关注100个",
              "price": "0.5",
              "sfcategory_id": 24,
              "deletetime": null
            },
            {
              "id": 49,
              "name": "快手粉丝关注50个",
              "price": "0.25",
              "sfcategory_id": 24,
              "deletetime": null
            },
            {
              "id": 50,
              "name": "快手粉丝关注10个",
              "price": "0.1",
              "sfcategory_id": 24,
              "deletetime": null
            }
          ]
        },
        {
          label:'快手点赞',
          value:3,
          children: [
            {
              "id": 54,
              "name": "快手作品点赞100个",
              "price": "0.08",
              "sfcategory_id": 25,
              "deletetime": null
            },
            {
              "id": 55,
              "name": "快手作品点赞50个",
              "price": "0.05",
              "sfcategory_id": 25,
              "deletetime": null
            },
            {
              "id": 56,
              "name": "快手作品点赞10个",
              "price": "0.02",
              "sfcategory_id": 25,
              "deletetime": null
            }
          ]
        },
        {
          label:'快手评论',
          value:4,
          children: [
            {
              "id": 57,
              "name": "快手围绕作品指定评论10条",
              "price": "0.1",
              "sfcategory_id": 26,
              "deletetime": null
            },
            {
              "id": 58,
              "name": "快手围绕作品随机评论10条",
              "price": "0.05",
              "sfcategory_id": 26,
              "deletetime": null
            }
          ]
        },
        {
          label:'快手收藏',
          value:5,
          children: [
            {
              "id": 59,
              "name": "快手作品收藏100个",
              "price": "0.09",
              "sfcategory_id": 27,
              "deletetime": null
            },
            {
              "id": 60,
              "name": "快手作品收藏50个",
              "price": "0.05",
              "sfcategory_id": 27,
              "deletetime": null
            }]
        },
        {
          label:'抖音播放',
          value:6,
          children: [
            {
              "id": 77,
              "name": "抖音播放1000次",
              "price": "0.01",
              "sfcategory_id": 28,
              "deletetime": null
            },
            {
              "id": 78,
              "name": "抖音播放10000次",
              "price": "0.05",
              "sfcategory_id": 28,
              "deletetime": null
            },
            {
              "id": 79,
              "name": "抖音播放十万次",
              "price": "0.45",
              "sfcategory_id": 28,
              "deletetime": null
            }]
        },
        {
          label:'抖音关注',
          value:7,
          children: [
            {
              "id": 74,
              "name": "抖音粉丝关注1000个",
              "price": "5.0",
              "sfcategory_id": 30,
              "deletetime": null
            },
            {
              "id": 75,
              "name": "抖音粉丝关注50个",
              "price": "1.0",
              "sfcategory_id": 30,
              "deletetime": null
            },
            {
              "id": 76,
              "name": "抖音粉丝关注10个",
              "price": "0.5",
              "sfcategory_id": 30,
              "deletetime": null
            }
          ]
        },
        {
          label:'抖音作品点赞',
          value:8,
          children: [
            {
              "id": 80,
              "name": "抖音作品点赞100个",
              "price": "0.08",
              "sfcategory_id": 31,
              "deletetime": null
            },
            {
              "id": 81,
              "name": "抖音作品点赞50个",
              "price": "0.05",
              "sfcategory_id": 31,
              "deletetime": null
            },
            {
              "id": 82,
              "name": "抖音作品点赞10个",
              "price": "0.02",
              "sfcategory_id": 31,
              "deletetime": null
            }
          ]
        },
        {
          label:'抖音评论',
          value:9,
          children: [
            {
              "id": 83,
              "name": "抖音围绕作品指定评论10条",
              "price": "0.1",
              "sfcategory_id": 32,
              "deletetime": null
            },
            {
              "id": 84,
              "name": "抖音围绕作品随机评论10条",
              "price": "0.05",
              "sfcategory_id": 32,
              "deletetime": null
            }
          ]
        },
        {
          label:'抖音收藏',
          value:10,
          children: [
            {
              "id": 85,
              "name": "抖音作品收藏100个",
              "price": "0.09",
              "sfcategory_id": 33,
              "deletetime": null
            },
            {
              "id": 86,
              "name": "抖音作品收藏50个",
              "price": "0.05",
              "sfcategory_id": 33,
              "deletetime": null
            }
          ]
        },
        {
          label:'Telegram电报-指定群拉真人',
          value:11,
          children: [
            {
              "id": 89,
              "name": "Telegram电报指定群拉真人克隆群用户 （1000人，可指定最近上线时间）",
              "price": "5.0",
              "sfcategory_id": 35,
              "deletetime": null
            }]
        },
        {
          label:'今日头条',
          value:12,
          children: [
            {
              "id": 220,
              "name": "今日头条在线粉丝100",
              "price": "0.5",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "今日头条在线粉丝500",
              "price": "1.5",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "今日头条在线粉丝1000",
              "price": "2.8",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "今日头条在线粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "今日头条僵尸粉1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 224,
              "name": "今日头条浏览量1000",
              "price": "0.5",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Linkedin领英',
          value:13,
          children: [
            {
              "id": 90,
              "name": "Linkedin领英公司粉丝100关注",
              "price": "0.15",
              "sfcategory_id": 37,
              "deletetime": null
            },
            {
              "id": 91,
              "name": "Linkedin领英个人粉丝100关注",
              "price": "0.1",
              "sfcategory_id": 37,
              "deletetime": null
            }
          ]
        },
        {
          label:'Tiktok',
          value:14,
          children: [
            {
              "id": 92,
              "name": "TikTok真人播放量1000次",
              "price": "0.25",
              "sfcategory_id": 39,
              "deletetime": null
            },
            {
              "id": 93,
              "name": "TikTok粉丝1000个",
              "price": "0.5",
              "sfcategory_id": 39,
              "deletetime": null
            },
            {
              "id": 94,
              "name": "TikTok视频点赞100个",
              "price": "0.25",
              "sfcategory_id": 39,
              "deletetime": null
            },
            {
              "id": 95,
              "name": "TikTok视频分享100次",
              "price": "0.55",
              "sfcategory_id": 39,
              "deletetime": null
            }
          ]
        },
        {
          label:'Facebook脸书主页+关注+赞',
          value:15,
          children: [
            {
              "id": 191,
              "name": "facebook---主页+关注1000次/0掉落/30天自动补充(通道一)",
              "price": "2.0",
              "sfcategory_id": 43,
              "deletetime": null
            },
            {
              "id": 192,
              "name": "facebook---主页+关注1000次/0掉落/30天自动补充(通道二)",
              "price": "1.9",
              "sfcategory_id": 43,
              "deletetime": null
            },
            {
              "id": 193,
              "name": "facebook---主页+关注1000次/0掉落/45天自动补充",
              "price": "1.3",
              "sfcategory_id": 43,
              "deletetime": null
            },
            {
              "id": 215,
              "name": "facebook---评论赞1000次/(通道一)",
              "price": "1.9",
              "sfcategory_id": 43,
              "deletetime": null
            },
            {
              "id": 216,
              "name": "facebook---主页赞1000次/(通道一)",
              "price": "1.9",
              "sfcategory_id": 43,
              "deletetime": null
            }
          ]
        },
        {
          label:'微信相关',
          value:16,
          children: [
            {
              "id": 106,
              "name": "公众号粉丝关注100个",
              "price": "0.55",
              "sfcategory_id": 44,
              "deletetime": null
            },
            {
              "id": 113,
              "name": "微信投票50个",
              "price": "0.5",
              "sfcategory_id": 44,
              "deletetime": null
            },
            {
              "id": 225,
              "name": "公众号文章20个赞",
              "price": "1.0",
              "sfcategory_id": 44,
              "deletetime": null
            },
            {
              "id": 226,
              "name": "公众号文章50个赞",
              "price": "2.0",
              "sfcategory_id": 44,
              "deletetime": null
            },
            {
              "id": 2083,
              "name": "微信视频号播放1000+",
              "price": "5.0",
              "sfcategory_id": 44,
              "deletetime": null
            },
            {
              "id": 2084,
              "name": "微信视频号点赞1000个",
              "price": "5.0",
              "sfcategory_id": 44,
              "deletetime": null
            },
            {
              "id": 2085,
              "name": "微信视频号关注1000个",
              "price": "5.0",
              "sfcategory_id": 44,
              "deletetime": null
            }
          ]
        },
        {
          label:'微博',
          value:17,
          children:[
            {
              "id": 107,
              "name": "随机粉丝1000个",
              "price": "0.5",
              "sfcategory_id": 47,
              "deletetime": null
            },
            {
              "id": 108,
              "name": "老号粉丝关注1000个",
              "price": "1.25",
              "sfcategory_id": 47,
              "deletetime": null
            },
            {
              "id": 109,
              "name": "博文点赞100个",
              "price": "0.05",
              "sfcategory_id": 47,
              "deletetime": null
            },
            {
              "id": 110,
              "name": "博文转发100次",
              "price": "0.3",
              "sfcategory_id": 47,
              "deletetime": null
            },
            {
              "id": 111,
              "name": "博文100阅读量",
              "price": "0.05",
              "sfcategory_id": 47,
              "deletetime": null
            },
            {
              "id": 112,
              "name": "博文评论20个（备注填写评论）",
              "price": "0.5",
              "sfcategory_id": 47,
              "deletetime": null
            }
          ]
        },
        {
          label:'Telegram电报-频道以前的帖子增加浏览量、表情',
          value:18,
          children: [
            {
              "id": 114,
              "name": "Telegram-频道增加阅读量1000次——最近发布的1条帖子 (通道3) ",
              "price": "0.01",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 144,
              "name": "Telegram-频道增加阅读量1000次——最近发布的1条帖子 (通道1) ",
              "price": "0.01",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 145,
              "name": "Telegram-频道增加阅读量1000次——最近发布的1条帖子 (通道4) ",
              "price": "0.01",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 146,
              "name": "Telegram-频道增加阅读量1000次——最近发布的1条帖子 (通道2) ",
              "price": "0.01",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 147,
              "name": "Telegram-频道增加阅读量1000次——最近发布的5条帖子",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 148,
              "name": "Telegram-频道增加阅读量1000次——最近发布的10条帖子",
              "price": "0.03",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 149,
              "name": "Telegram-频道增加阅读量1000次——最近发布的20条帖子",
              "price": "0.05",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 150,
              "name": "Telegram-频道增加阅读量1000次——最近发布的50条帖子",
              "price": "0.11",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 151,
              "name": "Telegram-频道增加阅读量1000次——最近发布的100条帖子",
              "price": "0.15",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 152,
              "name": "telegram频道帖子混合积极表情+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 153,
              "name": "telegram频道帖子混合负面表情+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 154,
              "name": "telegram频道帖子表情codea+免费浏览量1000次 ",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 155,
              "name": "telegram频道帖子表情codeb+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 156,
              "name": "telegram频道帖子表情codec+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 157,
              "name": "telegram频道帖子表情coded+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 158,
              "name": "telegram频道帖子表情codee+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 159,
              "name": "telegram频道帖子表情codef+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 160,
              "name": "telegram频道帖子表情codeg+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 161,
              "name": "telegram频道帖子表情codeh+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 162,
              "name": "telegram频道帖子表情codei+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 163,
              "name": "telegram频道帖子表情codej+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 164,
              "name": "telegram频道帖子表情codek+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 165,
              "name": "telegram频道帖子表情codel+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 166,
              "name": "telegram频道帖子英文随机评论1000次",
              "price": "1.5",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 167,
              "name": "telegram频道帖子自定义随机评论1000次(下单后发评论给客服)",
              "price": "8.5",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 168,
              "name": "telegram电子投票1000次",
              "price": "0.15",
              "sfcategory_id": 48,
              "deletetime": null
            }
          ]
        },
        {
          label:'Telegram电报-频道权重优化+免费浏览（上升全局搜索排名）',
          value:19,
          children: [
            {
              "id": 169,
              "name": "Telegram电报-全局排名 全面智能优化1000次 (随机国家) ",
              "price": "0.12",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 170,
              "name": "Telegram电报-全局排名 全面智能优化 1000次(中国) ",
              "price": "0.45",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 171,
              "name": "Telegram电报-全局排名 全面智能优化1000次 (美国) ",
              "price": "0.45",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 172,
              "name": "Telegram电报-全局排名 全面智能优化1000次 (俄罗斯) ",
              "price": "0.45",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 173,
              "name": "Telegram电报-全局排名 全面智能优化1000次 (印度) ",
              "price": "0.45",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 174,
              "name": "Telegram电报-全局排名 全面智能优化1000次 (意大利)",
              "price": "0.45",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 175,
              "name": "Telegram电报-全局排名 全面智能优化1000次 (印度尼西亚) ",
              "price": "0.45",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 176,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（随机国家）",
              "price": "0.01",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 177,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（中国）",
              "price": "0.15",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 178,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（美国）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 179,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（俄罗斯）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 180,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（印度）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 181,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（英国）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 182,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（巴西）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 183,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（德国）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 184,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（随机国家）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 185,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（中国）",
              "price": "0.03",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 186,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（美国）",
              "price": "0.04",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 187,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（俄罗斯）",
              "price": "0.04",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 188,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（巴西）",
              "price": "0.03",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 189,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（印度）",
              "price": "0.03",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 190,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（德国）",
              "price": "0.03",
              "sfcategory_id": 49,
              "deletetime": null
            }
          ]
        },
        {
          label:'小红书真人粉丝',
          value:20,
          children: [
            {
              "id": 122,
              "name": "小红书真人粉丝100个",
              "price": "2.0",
              "sfcategory_id": 50,
              "deletetime": null
            }
          ]
        },
        {
          label:'小红书真人点赞',
          value:21,
          children: [
            {
              "id": 123,
              "name": "小红书真人点赞100个",
              "price": "1.0",
              "sfcategory_id": 51,
              "deletetime": null
            }
          ]
        },
        {
          label:'小红书小眼睛阅读',
          value:22,
          children: [
            {
              "id": 124,
              "name": "小红书小眼睛阅读100个",
              "price": "0.1",
              "sfcategory_id": 52,
              "deletetime": null
            }
          ]
        },
        {
          label:'小红书作品评论',
          value:23,
          children: [
            {
              "id": 125,
              "name": "小红书作品评论10个",
              "price": "0.25",
              "sfcategory_id": 53,
              "deletetime": null
            }
          ]
        },
        {
          label:'Instagram',
          value:24,
          children: [
            {
              "id": 126,
              "name": "instagram粉丝1000个(国家随机)",
              "price": "3.0",
              "sfcategory_id": 54,
              "deletetime": null
            },
            {
              "id": 127,
              "name": "instagram粉丝1000个(私聊客服指定国家)",
              "price": "3.5",
              "sfcategory_id": 54,
              "deletetime": null
            },
            {
              "id": 128,
              "name": "instagram点赞500个",
              "price": "0.15",
              "sfcategory_id": 54,
              "deletetime": null
            },
            {
              "id": 129,
              "name": "instagram评论1000个（评论内容发给客服）",
              "price": "2.5",
              "sfcategory_id": 54,
              "deletetime": null
            }
          ]
        },
        {
          label:'百度贴吧',
          value:25,
          children: [
            {
              "id": 130,
              "name": "百度贴吧帖子点赞20个",
              "price": "0.2",
              "sfcategory_id": 55,
              "deletetime": null
            },
            {
              "id": 224,
              "name": "百度贴吧帖子评论10个（备注填写评论）",
              "price": "1.0",
              "sfcategory_id": 55,
              "deletetime": null
            }
          ]
        },
        {
          label:'Youtube视频/短片播放量',
          value:26,
          children: [
            {
              "id": 132,
              "name": "youtube视频/短片播放量1000次",
              "price": "1.75",
              "sfcategory_id": 56,
              "deletetime": null
            }
          ]
        },
        {
          label:'Youtube蓝V',
          value:27,
          children: [
            {
              "id": 54,
              "name": "youtube蓝V",
              "price": "5",
              "sfcategory_id": 25,
              "deletetime": null
            }
          ]
        },
        {
          label:'Youtube视频/短片评论',
          value:28,
          children: [
            {
              "id": 133,
              "name": "youtube随机国家随机评论1000个",
              "price": "2.0",
              "sfcategory_id": 57,
              "deletetime": null
            },
            {
              "id": 134,
              "name": "youtube指定国家指定评论1000个",
              "price": "3.0",
              "sfcategory_id": 57,
              "deletetime": null
            }
          ]
        },
        {
          label:'Youtube点赞',
          value:29,
          children: [
            {
              "id": 131,
              "name": "youtube点赞1000个",
              "price": "1.0",
              "sfcategory_id": 58,
              "deletetime": null
            }
          ]
        },
        {
          label:'Youtube订阅',
          value:30,
          children: [
            {
              "id": 135,
              "name": "youtube粉丝订阅1000个",
              "price": "2.25",
              "sfcategory_id": 59,
              "deletetime": null
            }]
        },
        {
          label:'Telegram电报-开通会员',
          value:31,
          children: [
            {
              "id": 136,
              "name": "Telegram电报大会员(12个月)",
              "price": "25.0",
              "sfcategory_id": 60,
              "deletetime": null
            },
            {
              "id": 137,
              "name": "Telegram电报大会员(6个月)",
              "price": "16.0",
              "sfcategory_id": 60,
              "deletetime": null
            },
            {
              "id": 138,
              "name": "Telegram电报大会员(3个月)",
              "price": "9.0",
              "sfcategory_id": 60,
              "deletetime": null
            }
          ]
        },
        {
          label:'Telegram电报-最便宜的僵尸粉',
          value:32,
          children: [
            {
              "id": 139,
              "name": "Telegram电报-频道僵尸粉1000人",
              "price": "0.23",
              "sfcategory_id": 61,
              "deletetime": null
            },
            {
              "id": 212,
              "name": "Telegram电报-群组僵尸粉1000人",
              "price": "0.23",
              "sfcategory_id": 61,
              "deletetime": null
            }
          ]
        },
        {
          label:'Telegram电报-极速粉',
          value:33,
          children: [
            {
              "id": 140,
              "name": "Telegram电报-伊朗粉丝1000个 (群主号必须为+98伊朗号码) 50k/天 ，20-40%掉落",
              "price": "0.5",
              "sfcategory_id": 62,
              "deletetime": null
            },
            {
              "id": 141,
              "name": "Telegram电报-极速粉1000个(超高速)速度60K/天，20-40%掉落",
              "price": "0.65",
              "sfcategory_id": 62,
              "deletetime": null
            },
            {
              "id": 142,
              "name": "Telegram电报-极速粉1000个(通道一)速度30K/天，10-20%掉落",
              "price": "0.45",
              "sfcategory_id": 62,
              "deletetime": null
            },
            {
              "id": 143,
              "name": "Telegram电报-极速粉1000个(0掉落)速度10-20K/天",
              "price": "0.9",
              "sfcategory_id": 62,
              "deletetime": null
            },
            {
              "id": 213,
              "name": "Telegram电报-极速粉1000个(粉丝昵称中文)速度30K/天，10-20%掉落",
              "price": "0.55",
              "sfcategory_id": 62,
              "deletetime": null
            },
            {
              "id": 214,
              "name": "Telegram电报-极速粉1000个(粉丝昵称英文)速度30K/天，10-20%掉落",
              "price": "0.45",
              "sfcategory_id": 62,
              "deletetime": null
            }
          ]
        },
        {
          label:'Telegram电报-在线粉',
          value:35,
          children: [
            {
              "id": 220,
              "name": "telegram在线粉丝100",
              "price": "0.3",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "telegram在线粉丝500",
              "price": "1.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "telegram在线粉丝1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "telegram在线粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Facebook群组粉丝',
          value:36,
          children: [
            {
              "id": 194,
              "name": "facebook---群组粉丝1000个♻️30天补充（通道一）",
              "price": "1.25",
              "sfcategory_id": 63,
              "deletetime": null
            },
            {
              "id": 195,
              "name": "facebook---群组粉丝1000个♻️30天补充（通道二）",
              "price": "1.25",
              "sfcategory_id": 63,
              "deletetime": null
            },
            {
              "id": 196,
              "name": "facebook---群组粉丝1000个♻️30天补充（通道三）",
              "price": "1.25",
              "sfcategory_id": 63,
              "deletetime": null
            }
          ]
        },
        {
          label:'Twitter推特关注',
          value:37,
          children: [
            {
              "id": 197,
              "name": "推特关注1000粉，有掉落无补充/10K每天/低速掉落",
              "price": "0.35",
              "sfcategory_id": 64,
              "deletetime": null
            },
            {
              "id": 198,
              "name": "推特关注1000粉，30天内掉粉免费补充/5K每天/低速掉落",
              "price": "0.5",
              "sfcategory_id": 64,
              "deletetime": null
            },
            {
              "id": 199,
              "name": "推特关注1000粉，30天内掉粉免费补充/20K每天/很少掉落或者不掉",
              "price": "0.52",
              "sfcategory_id": 64,
              "deletetime": null
            }
          ]
        },
        {
          label:'Twitter推特点赞',
          value:38,
          children: [
            {
              "id": 200,
              "name": "Twitter 推特点赞1000个 无补充/10k每天/少量掉落",
              "price": "0.5",
              "sfcategory_id": 65,
              "deletetime": null
            },
            {
              "id": 201,
              "name": "Twitter 推特点赞1000个（高质量）无补充/10k每天/少量掉落",
              "price": "0.75",
              "sfcategory_id": 65,
              "deletetime": null
            },
            {
              "id": 202,
              "name": "Twitter 推特点赞1000个,30天补充/10k每天/少量掉落 ",
              "price": "1.0",
              "sfcategory_id": 65,
              "deletetime": null
            }
          ]
        },
        {
          label:'Twitter推特转推',
          value:39,
          children: [
            {
              "id": 203,
              "name": "Twitter 推特转推1000次  20K每天/低掉落无补充",
              "price": "0.75",
              "sfcategory_id": 66,
              "deletetime": null
            },
            {
              "id": 204,
              "name": "Twitter 推特转推1000次  100-300每天/0掉落",
              "price": "0.85",
              "sfcategory_id": 66,
              "deletetime": null
            }
          ]
        },
        {
          label:'Facebook脸书评论',
          value:40,
          children: [
            {
              "id": 205,
              "name": "facebook评论 5星+随机内容1000条",
              "price": "5.0",
              "sfcategory_id": 67,
              "deletetime": null
            },
            {
              "id": 206,
              "name": "facebook评论 5星+自定义内容1000条",
              "price": "7.5",
              "sfcategory_id": 67,
              "deletetime": null
            }
          ]
        },
        {
          label:'Bilibili/b站粉丝关注',
          value:41,
          children: [
            {
              "id": 207,
              "name": "b站粉丝关注1000人",
              "price": "0.5",
              "sfcategory_id": 68,
              "deletetime": null
            }
          ]
        },
        {
          label:'QQ群拉僵尸',
          value:42,
          children: [
            {
              "id": 208,
              "name": "QQ群拉假人200人",
              "price": "0.05",
              "sfcategory_id": 69,
              "deletetime": null
            },
            {
              "id": 209,
              "name": "QQ群拉假人500人",
              "price": "0.1",
              "sfcategory_id": 69,
              "deletetime": null
            },
            {
              "id": 210,
              "name": "QQ群拉假人2000人",
              "price": "0.35",
              "sfcategory_id": 69,
              "deletetime": null
            }
          ]
        },
        {
          label:'Line公众号粉丝',
          value:43,
          children: [
            {
              "id": 211,
              "name": "Line公众号粉丝1000人",
              "price": "15.0",
              "sfcategory_id": 70,
              "deletetime": null
            }
          ]
        },
        {
          label:'美团点赞',
          value:44,
          children: [
            {
              "id": 217,
              "name": "美团点赞-100个",
              "price": "2.0",
              "sfcategory_id": 71,
              "deletetime": null
            }
          ]
        },
        {
          label:'美团好/差评',
          value:45,
          children: [
            {
              "id": 218,
              "name": "美团差评-20个",
              "price": "5.0",
              "sfcategory_id": 72,
              "deletetime": null
            },
            {
              "id": 219,
              "name": "美团好评-20个",
              "price": "5.0",
              "sfcategory_id": 72,
              "deletetime": null
            }
          ]
        },
        {
          label:'抖音评论点赞',
          value:46,
          children: [
            {
              "id": 220,
              "name": "抖音评论点赞10个",
              "price": "0.02",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "抖音评论点赞50个",
              "price": "0.06",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "抖音评论点赞100个",
              "price": "0.1",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "抖音评论点赞500个",
              "price": "0.4",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Ins视频浏览量',
          value:47,
          children: [
            {
              "id": 220,
              "name": "ins视频浏览量10",
              "price": "0.03",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "ins视频浏览量50",
              "price": "0.12",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "ins视频浏览量100",
              "price": "0.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "ins视频浏览量500",
              "price": "0.9",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'微信公众号刷浏览量',
          value:48,
          children: [
            {
              "id": 220,
              "name": "微信公众号浏览量10",
              "price": "0.03",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "微信公众号浏览量50",
              "price": "0.12",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "微信公众号浏览量100",
              "price": "0.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "微信公众号浏览量500",
              "price": "0.9",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Whatsapp刷粉',
          value:49,
          children: [
            {
              "id": 220,
              "name": "whatsapp粉丝10",
              "price": "0.03",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "whatsapp粉丝50",
              "price": "0.12",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "whatsapp粉丝100",
              "price": "0.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "whatsapp粉丝500",
              "price": "0.9",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Whatsapp拉人',
          value:50,
          children: [
            {
              "id": 220,
              "name": "whatsapp拉人100",
              "price": "0.3",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "whatsapp粉丝500",
              "price": "1.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "whatsapp粉丝1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "whatsapp粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'TT语音粉丝',
          value:51,
          children: [
            {
              "id": 220,
              "name": "TT语音在线粉丝100",
              "price": "0.3",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "TT语音在线粉丝500",
              "price": "1.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "TT语音在线粉丝1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "TT语音在线粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "TT语音僵尸粉1000",
              "price": "1",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Kakao粉丝',
          value:52,
          children: [
            {
              "id": 220,
              "name": "Kakao在线粉丝100",
              "price": "0.3",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "Kakao在线粉丝500",
              "price": "1.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "Kakao在线粉丝1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "Kakao在线粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "Kakao僵尸粉1000",
              "price": "1",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Potato粉丝',
          value:53,
          children: [
            {
              "id": 220,
              "name": "potato在线粉丝100",
              "price": "0.5",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "potato在线粉丝500",
              "price": "1.5",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "potato在线粉丝1000",
              "price": "2.8",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "potato在线粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "potato僵尸粉1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 224,
              "name": "potato浏览量1000",
              "price": "0.5",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'OKC粉丝',
          value:54,
          children: [
            {
              "id": 220,
              "name": "OKC在线粉丝100",
              "price": "0.3",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "OKC在线粉丝500",
              "price": "1.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "OKC在线粉丝1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "OKC在线粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "OKC僵尸粉1000",
              "price": "1",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        }
      ],
      tableData: [],
      userListInfo:{}
    }
  },
  created() {
  },
  methods:{
    getList(e){
      this.$set(this.ruleForms,'region',undefined);
      this.$set(this.ruleForms,'price',0);
      this.$set(this.ruleForms,'priceTotal',0);
      this.listData.forEach(item=>{
        if(item.value===e){
          this.tableData = item.children
        }
      })
    },
    getPrice(e){
      this.tableData.forEach(item=>{
        if(item.id===e){
          this.$set(this.ruleForms,'price',item.price);
        }
      })
      this.$set(this.ruleForms,'priceTotal',(Number(this.ruleForms.price)*Number(this.ruleForms.number)).toFixed(2));
    },
    handleChange(){
      this.$set(this.ruleForms,'priceTotal',(Number(this.ruleForms.price)*Number(this.ruleForms.number)).toFixed(2));
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$message.error('余额不足，请先充值！');
          this.$router.push('/recharge')
          // alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
};
</script>

<style scoped>
.box-cardThreeList{
  background: #373539;
  border: 1px solid #414243;
  margin-top: 10px;
}
.contListBoxs p{
  font-size: 13px;
  color: #E6EAF2;
}
::v-deep .el-input__inner{
  color: #D0D3DB;
  background: #373539;
  border: 1px solid #4C4D4F;
}
::v-deep .el-textarea__inner{
  color: #D0D3DB;
  background: #373539;
  border: 1px solid #4C4D4F;
}
::v-deep .el-input.is-disabled .el-input__inner{
  background: #262727;
  border: 1px solid #4C4D4F;
  border-right: none;
}
::v-deep .el-input-group__append, .el-input-group__prepend{
  background: #262727;
  border: 1px solid #4C4D4F;
}

::v-deep .el-input-number__decrease.is-disabled{
  background: #262727;
  border-right: 1px solid #4C4D4F;
}
::v-deep .el-input-number__increase{
  background: #262727;
  border-left: 1px solid #4C4D4F;
}
::v-deep .el-input-number__decrease{
  background: #262727;
  border-right: 1px solid #4C4D4F;
}
::v-deep .el-input__inner::-webkit-input-placeholder{
  color: #85878C;
}
::v-deep .el-textarea__inner::-webkit-input-placeholder{
  color: #85878C;
}
::v-deep .el-select-dropdown{
  background-color: #1D1E1F;
  border: 1px solid #414243;
}
::v-deep .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
  background-color: #262727;
}
::v-deep .el-popper[x-placement^=bottom] .popper__arrow::after{
  border-bottom-color: #1D1E1F;
}
</style>
