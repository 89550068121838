<template>
  <div>
    <div class="home">
      <el-row class="card-position" :gutter="20">
        <el-col :xs="24" :sm="12">
          <h2>社交媒体营销一站式服务</h2>
          <p>国际最优秀的面板_华人第一外贸社交下单平台 ...</p>
          <p style="line-height: 22px">
            企鹅刷粉是国际上最优秀的社交网络服务面板之一。我们始终致力于提供最优品质的instagram
            facebook twitter youtube
            telegram等服务，我们保证如实的描述服务质量，保证所有的服务每天都会进行测试，保证服务的实际效果和描述一致。我们拥有世界上独一无二的监控系统对每一个服务进行实时统计以便客户挑选使用。每个种类我们都按照质量来提供不同价格的服务来供您选择，有市面上最便宜的粉丝和点赞，也有市面上最高质量的粉丝和点赞。我们非常自豪的以最便宜的价格提供最优质的服务。如果在市场上您找到了比我们更便宜的服务，请在客服系统告诉我们，我们一定会击败他。企鹅刷粉
            是您终极的社交网络推广解决方案。
          </p>
          <p>别再买贵的了！购买便宜优质的服务</p>
        </el-col>
        <el-col :xs="24" v-if="isLogin" :sm="12">
          <el-card shadow="always">
            <h3 style="color: #fff">欢迎登录</h3>
            <el-form
                :model="ruleForm"
                status-icon
                :rules="rules"
                ref="ruleForm"
                label-width="80px"
                class="demo-ruleForm"
            >
              <el-form-item label="用户名" prop="userName">
                <el-input
                    v-model="ruleForm.userName"
                    placeholder="请输入用户名"
                    autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="pass">
                <el-input
                    type="password"
                    v-model="ruleForm.pass"
                    autocomplete="off"
                    placeholder="请输入密码"
                ></el-input>
              </el-form-item>
            </el-form>
            <!-- 登录按钮 -->
            <div class="buttons" v-if="isLogin">
              <el-button
                  class="login-button"
                  type="warning"
                  size="large"
                  @click="submitForm('ruleForm')"
              >登录</el-button
              >
              <el-button
                  type="danger"
                  class="login-button"
                  size="large"
                  @click="resiget('registRuleForm')"
              >注册</el-button
              >
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" v-if="!isLogin" :sm="12">
          <el-card shadow="always">
            <h3 style="color: #fff">欢迎注册</h3>
            <el-form
                :model="registForm"
                status-icon
                :rules="registRules"
                ref="registRuleForm"
                label-width="80px"
                class="demo-ruleForm"
            >
              <el-form-item label="用户名" prop="userName">
                <el-input
                    v-model="registForm.userName"
                    placeholder="请输入用户名"
                    autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input
                    v-model="registForm.email"
                    placeholder="请输入邮箱"
                    autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="pass">
                <el-input
                    type="password"
                    v-model="registForm.pass"
                    autocomplete="off"
                    placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="checkPass">
                <el-input
                    type="password"
                    v-model="registForm.checkPass"
                    autocomplete="off"
                    placeholder="请输入密码"
                ></el-input>
              </el-form-item>
            </el-form>
            <!-- 注册按钮 -->
            <div class="buttons" v-if="!isLogin">
              <el-button
                  class="login-button"
                  type="warning"
                  size="large"
                  @click="subResiget('registRuleForm')"
              >注册</el-button
              >
              <el-button
                  type="danger"
                  class="login-button"
                  size="large"
                  @click="backLogin('ruleForm')"
              >返回登录</el-button
              >
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="bgimg"></div>
    <div class="text-h-v-center">
      <h1>以下是您应该选择我们的理由</h1>
    </div>
    <div class="home-bottom">
      <el-row :gutter="20" class="card-position">
        <el-col :xs="12" :sm="6">
          <el-card shadow="always">
            <div class="card-content">
              <i class="el-icon-user-solid iconbg"></i>
              <p>我们是领导者</p>
              <p>您可以从我们的价格中了解</p>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="12" :sm="6">
          <el-card shadow="always">
            <div class="card-content">
              <i class="el-icon-message iconbg"></i>
              <p>付款方法</p>
              <p>从我们提供的多种付款方式中进行选择。</p>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="12" :sm="6">
          <el-card shadow="always">
            <div class="card-content">
              <i class="el-icon-star-off iconbg"></i>
              <p>极其便宜</p>
              <p>您会惊讶于我们所有的价格都如此之低。</p>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="12" :sm="6">
          <el-card shadow="always">
            <div class="card-content">
              <i class="el-icon-s-promotion iconbg"></i>
              <p>交货非常快</p>
              <p>我们的面板的订单交货时间非常短。</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.registForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      ruleForm: {
        userName: '',
        pass: '',
      },
      registForm: {
        userName: '',
        email: '',
        pass: '',
        checkPass: '',
      },
      LoginLists:[],
      isLogin: true,
      rules: {
        userName: [
          { required: true, trigger: 'blur', message: '请输入用户名' },
        ],
        pass: [{ required: true, trigger: 'blur', message: '请输入密码' }],
        // checkPass: [{ validator: validatePass2, trigger: 'blur' }],
        // age: [{ validator: checkAge, trigger: 'blur' }],
      },
      registRules: {
        userName: [
          { required: true, trigger: 'blur', message: '请输入用户名' },
        ],
        email: [
          { required: true, trigger: 'blur', message: '请输入正确的邮箱地址' },
          {
            type: 'email',
            trigger: ['blur', 'change'],
            message: '请输入正确的邮箱地址',
          },
        ],
        pass: [{ trigger: 'blur', required: true, message: '请输入密码' }],
        checkPass: [
          { validator: validatePass2, trigger: 'blur', required: true },
        ],
      },
    };
  },
  created() {
    //当前登录用户
    if(JSON.parse(localStorage.getItem('guojishuafenInfomations'))){
      this.ruleForm = JSON.parse(localStorage.getItem('guojishuafenInfomations'));
      console.log(this.ruleForm,'this.ruleForm')
      this.isLogin = true
    }
    //所有登录用户
    if(JSON.parse(localStorage.getItem('guojishuafenInfomationsLists'))){
      this.LoginLists = JSON.parse(localStorage.getItem('guojishuafenInfomationsLists'));
      console.log(this.LoginLists,'this.LoginLists')
    }
  },
  methods: {
    unique(arr) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i].userName == arr[j].userName) {
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    submitForm: function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          let userList = localStorage.getItem('userList');
          if (userList) {
            const list = JSON.parse(userList);
            const result = list.some(
                (item) => item.userName === this.ruleForm.userName
            );
            if (result) {
              this.$message.success('登录成功');
              localStorage.setItem('isLogin', result);
              localStorage.setItem('userListInfos', JSON.stringify(this.ruleForm));
              this.$router.replace({
                path: '/order',
              });
            } else {
              this.$message.warning('请先注册');
              // this.$message.error('用户名或密码错误');
            }
          }else{
            this.$message.warning('请先注册');
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    subResiget(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('submit!');
          let userList = localStorage.getItem('userList');
          console.log(userList, 'userList');
          if (userList) {
            const list = JSON.parse(userList);
            let users = {
              userName:this.registForm.userName,
              pass:this.registForm.pass
            }
            if(!this.LoginLists){
              this.LoginLists = []
            }
            let arr = list;
            arr.push(users);
            // this.LoginLists = arr
            this.LoginLists = this.unique(arr);
            // this.LoginLists = this.unique(arr);
            // list.push({
            //   userName: this.registForm.userName,
            //   pass: this.registForm.pass,
            // });
          } else {
            this.LoginLists = [
              {
                userName: this.registForm.userName,
                pass: this.registForm.pass,
              }
            ]
            // userList = [
            //   {
            //     userName: this.registForm.userName,
            //     pass: this.registForm.pass,
            //   },
            // ];
          }
          localStorage.setItem('userList', JSON.stringify(this.LoginLists));
          // localStorage.setItem('userList', JSON.stringify(userList));
          this.$message.success('注册成功');
          this.backLogin('ruleForm');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    resiget(formName) {
      this.isLogin = false;
      this.$nextTick(() => {
        this.resetForm(formName);
      });
    },
    backLogin(formName) {
      this.isLogin = true;
      this.$nextTick(() => {
        this.resetForm(formName);
      });
    },
  },
};
</script>
<style scoped lang="less">
.home {
  width: 100vw;
  padding: 3em 5%;
  box-sizing: border-box;
}
.card-position {
  display: block;
  // width: 90%;
  /* margin: 1rem 5%; */
  /* margin-left: 5%; */
}
/deep/.el-card.is-always-shadow {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.72);
}
/deep/.el-card__body {
  background-color: #373539 !important;
  padding: 40px 20px;
}
/deep/.el-input__inner {
  background-color: transparent;
  border-color: #4c4d4f;
  color: #fff;
}
/deep/.el-card {
  border: 1px solid #373539 !important;
}
/deep/.el-form-item__label {
  color: #fff;
}
.buttons {
  display: flex;
  .login-button {
    flex: 1;
  }
}
.bgimg {
  height: 100px;
  background-image: url(../../assets/bg.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  height: 18vh;
  background-size: auto;
}
.text-h-v-center {
  h1 {
    margin: 0;
  }
  height: 15vh;
  text-align: center;
  background-color: rgb(55, 53, 57);
}
.home-bottom {
  background-color: rgb(55, 53, 57);
  padding-bottom: 5vh;
  margin-top: 20px;
  padding: 0 5%;
  width: 100vw;
  padding-bottom: 40px;
  padding-top: 20px;
  box-sizing: border-box;
}
.iconbg {
  background-color: #4b3d3c;
  width: 100% !important;
  height: 10vh !important;
  color: #ff8355 !important;
  border-radius: 10px;
  font-size: 50px;
  line-height: 10vh;
  text-align: center;
}
.card-content {
  color: #fff;
}
</style>
