<template>
  <div>
    <topBoxs></topBoxs>
    <div class="titleLables">由于第三方充值风控限制，目前只能USDT充值，请针对相应钱包进行转账充值,充值成功自动到账。</div>
    <div style="width:54%;margin-left: 23%;">
      <el-card class="box-cardTwoList" shadow="always">
        <p>1.输入数量 点击支付跳转到支付页面</p>
        <p>2.选择您要支付的货币类型如（USDT-TRC20</p>
        <p>3.选择相应的钱包付款</p>
        <p>4.虚拟自动充值10-20分钟自动到账，如果您不会自动充值，请联系人工客户充值</p>
        <p style="color: #EA3323;">5.充值异常，请联系客服</p>
        <div class="setColor">
          <span>
            <el-button size="samll" style="background: #282219;color: #DBA550;border:1px solid #282219;">充值数量</el-button>
            <el-input-number size="samll" v-model="num" :min="1" :max="10" label="" style="margin-left: 10px;width: 50%;background: #373539;"></el-input-number>
          </span>
        </div>
        <div style="margin-top: 20px;">
          <el-button size="samll" type="warning" @click="gopay">立即充值</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import topBoxs from "@/views/loggedInMall/components/topBoxs.vue";
export default {
  components: {
    topBoxs
  },
  data() {
    return {
      num:1,
    }
  },
  created() {

  },
  methods:{
    gopay(){
      window.location.href = 'http://sms.sim-account.top/scan/sss2/usdtpay_select/pay.html?POZMMChy'
    }
  }
};
</script>

<style scoped>
.titleLables{
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-cardTwoList{
  background: #373539;
  border: 1px solid #414243;
  margin-top: 10px;
}
.box-cardTwoList p{
  font-size: 14px;
  color: #E5EAF2;
}
.setColor ::v-deep .el-input__inner{
  background-color: #373539;
  border: 1px solid #4C4D50;
  color: #D0D3DB;
}
.setColor ::v-deep .el-input-number__decrease{
  background: #262728;
  border-right: 1px solid #4C4D50;
}
.setColor ::v-deep .el-input-number__increase{
  background: #262728;
  border-left: 1px solid #4C4D50;
}
</style>
