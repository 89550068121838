<template>
  <el-footer class="footer">
    <h1 style="color: #7FCDFA;cursor: pointer;" @click="getUser"><span class="el-icon-s-custom" style="padding-right: 10px;"></span>点击联系24小时客服</h1>
  </el-footer>
</template>
<script>
export default {
  methods:{
    getUser(){
      window.location.href = "https://t.me/xincai8888"
    }
  }
}
</script>
<style scoped>
.footer {
  text-align: center;
}
</style>
