<template>
  <div>
    <LoginHeader />
    <router-view> </router-view>
    <Footer />
  </div>
</template>
<script>
import LoginHeader from '@/components/LoginHeader.vue';
import Footer from '@/components/Footer.vue';
export default {
  components: {
    LoginHeader,
    Footer,
  },
};
</script>
