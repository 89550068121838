<template>
  <div>
    <div style="margin-top: 20px;">
      <el-card class="box-card" shadow="always" >
        <div style="width:100%;display:flex;">
          <el-dropdown trigger="click">
            <el-button type="danger">
              选择分类<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="simu-dropdown">
              <el-dropdown-item v-for="(item,index) in listData" :key="index" @click.native="getTableList(item)">{{item.label}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-input placeholder="请搜索相应服务" v-model="searchValue" style="padding-left: 10px;">
            <template slot="append">
              <i class="el-icon-search"></i>
            </template>
          </el-input>
        </div>
      </el-card>
    </div>
    <el-alert
        style="width: 90%;margin-left: 5%;margin-top: 20px;"
        title="以下单价为usdt,非人民币"
        type="error"
        effect="dark">
    </el-alert>
    <div style="margin-top: 20px;">
      <el-card class="box-card" shadow="always" >
        <el-table
            :data="tableData"
            stripe
            style="width: 100%;background-color: #666 !important;">
          <el-table-column
              prop="id"
              label="id"
              width="60">
          </el-table-column>
          <el-table-column
              prop="name"
              label="服务名称"
              >
          </el-table-column>
          <el-table-column
              prop="price"
              width="80"
              label="价格">
          </el-table-column>
          <el-table-column
              width="80"
              align="right">
            <template slot="header"></template>
            <template slot-scope="scope">
              <el-button
                  type="danger"
                  size="mini"
                  @click="handleEdit(scope.$index, scope.row)">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      searchValue:'',
      listData:[
        {
          label:'快手播放',
          children:[
            {
              "id": 51,
              "name": "快手播放1000次",
              "price": "0.01",
              "sfcategory_id": 22,
              "deletetime": null
            },
            {
              "id": 52,
              "name": "快手播放10000次",
              "price": "0.05",
              "sfcategory_id": 22,
              "deletetime": null
            },
            {
              "id": 53,
              "name": "快手播放十万次",
              "price": "0.45",
              "sfcategory_id": 22,
              "deletetime": null
            }
          ]
        },
        {
          label:'快手关注',
          children:  [
            {
              "id": 48,
              "name": "快手粉丝关注100个",
              "price": "0.5",
              "sfcategory_id": 24,
              "deletetime": null
            },
            {
              "id": 49,
              "name": "快手粉丝关注50个",
              "price": "0.25",
              "sfcategory_id": 24,
              "deletetime": null
            },
            {
              "id": 50,
              "name": "快手粉丝关注10个",
              "price": "0.1",
              "sfcategory_id": 24,
              "deletetime": null
            }
          ]
        },
        {
          label:'快手点赞',
          children: [
            {
              "id": 54,
              "name": "快手作品点赞100个",
              "price": "0.08",
              "sfcategory_id": 25,
              "deletetime": null
            },
            {
              "id": 55,
              "name": "快手作品点赞50个",
              "price": "0.05",
              "sfcategory_id": 25,
              "deletetime": null
            },
            {
              "id": 56,
              "name": "快手作品点赞10个",
              "price": "0.02",
              "sfcategory_id": 25,
              "deletetime": null
            }
          ]
        },
        {
          label:'快手评论',
          children: [
            {
              "id": 57,
              "name": "快手围绕作品指定评论10条",
              "price": "0.1",
              "sfcategory_id": 26,
              "deletetime": null
            },
            {
              "id": 58,
              "name": "快手围绕作品随机评论10条",
              "price": "0.05",
              "sfcategory_id": 26,
              "deletetime": null
            }
          ]
        },
        {
          label:'快手收藏',
          children: [
              {
            "id": 59,
            "name": "快手作品收藏100个",
            "price": "0.09",
            "sfcategory_id": 27,
            "deletetime": null
          },
            {
              "id": 60,
              "name": "快手作品收藏50个",
              "price": "0.05",
              "sfcategory_id": 27,
              "deletetime": null
            }]
        },
        {
          label:'抖音播放',
          children: [
          {
            "id": 77,
            "name": "抖音播放1000次",
            "price": "0.01",
            "sfcategory_id": 28,
            "deletetime": null
          },
            {
              "id": 78,
              "name": "抖音播放10000次",
              "price": "0.05",
              "sfcategory_id": 28,
              "deletetime": null
            },
            {
              "id": 79,
              "name": "抖音播放十万次",
              "price": "0.45",
              "sfcategory_id": 28,
              "deletetime": null
            }]
        },
        {
          label:'抖音关注',
          children: [
            {
              "id": 74,
              "name": "抖音粉丝关注1000个",
              "price": "5.0",
              "sfcategory_id": 30,
              "deletetime": null
            },
            {
              "id": 75,
              "name": "抖音粉丝关注50个",
              "price": "1.0",
              "sfcategory_id": 30,
              "deletetime": null
            },
            {
              "id": 76,
              "name": "抖音粉丝关注10个",
              "price": "0.5",
              "sfcategory_id": 30,
              "deletetime": null
            }
          ]
        },
        {
          label:'抖音作品点赞',
          children: [
            {
              "id": 80,
              "name": "抖音作品点赞100个",
              "price": "0.08",
              "sfcategory_id": 31,
              "deletetime": null
            },
            {
              "id": 81,
              "name": "抖音作品点赞50个",
              "price": "0.05",
              "sfcategory_id": 31,
              "deletetime": null
            },
            {
              "id": 82,
              "name": "抖音作品点赞10个",
              "price": "0.02",
              "sfcategory_id": 31,
              "deletetime": null
            }
          ]
        },
        {
          label:'抖音评论',
          children: [
            {
              "id": 83,
              "name": "抖音围绕作品指定评论10条",
              "price": "0.1",
              "sfcategory_id": 32,
              "deletetime": null
            },
            {
              "id": 84,
              "name": "抖音围绕作品随机评论10条",
              "price": "0.05",
              "sfcategory_id": 32,
              "deletetime": null
            }
          ]
        },
        {
          label:'抖音收藏',
          children: [
            {
              "id": 85,
              "name": "抖音作品收藏100个",
              "price": "0.09",
              "sfcategory_id": 33,
              "deletetime": null
            },
            {
              "id": 86,
              "name": "抖音作品收藏50个",
              "price": "0.05",
              "sfcategory_id": 33,
              "deletetime": null
            }
          ]
        },
        {
          label:'Telegram电报-指定群拉真人',
          children: [
           {
            "id": 89,
            "name": "Telegram电报指定群拉真人克隆群用户 （1000人，可指定最近上线时间）",
            "price": "5.0",
            "sfcategory_id": 35,
            "deletetime": null
          }]
        },
        {
          label:'今日头条',
          children: [
            {
              "id": 220,
              "name": "今日头条在线粉丝100",
              "price": "0.5",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "今日头条在线粉丝500",
              "price": "1.5",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "今日头条在线粉丝1000",
              "price": "2.8",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "今日头条在线粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "今日头条僵尸粉1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 224,
              "name": "今日头条浏览量1000",
              "price": "0.5",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Linkedin领英',
          children: [
            {
              "id": 90,
              "name": "Linkedin领英公司粉丝100关注",
              "price": "0.15",
              "sfcategory_id": 37,
              "deletetime": null
            },
            {
              "id": 91,
              "name": "Linkedin领英个人粉丝100关注",
              "price": "0.1",
              "sfcategory_id": 37,
              "deletetime": null
            }
          ]
        },
        {
          label:'Tiktok',
          children: [
            {
              "id": 92,
              "name": "TikTok真人播放量1000次",
              "price": "0.25",
              "sfcategory_id": 39,
              "deletetime": null
            },
            {
              "id": 93,
              "name": "TikTok粉丝1000个",
              "price": "0.5",
              "sfcategory_id": 39,
              "deletetime": null
            },
            {
              "id": 94,
              "name": "TikTok视频点赞100个",
              "price": "0.25",
              "sfcategory_id": 39,
              "deletetime": null
            },
            {
              "id": 95,
              "name": "TikTok视频分享100次",
              "price": "0.55",
              "sfcategory_id": 39,
              "deletetime": null
            }
          ]
        },
        {
          label:'Facebook脸书主页+关注+赞',
          children: [
            {
              "id": 191,
              "name": "facebook---主页+关注1000次/0掉落/30天自动补充(通道一)",
              "price": "2.0",
              "sfcategory_id": 43,
              "deletetime": null
            },
            {
              "id": 192,
              "name": "facebook---主页+关注1000次/0掉落/30天自动补充(通道二)",
              "price": "1.9",
              "sfcategory_id": 43,
              "deletetime": null
            },
            {
              "id": 193,
              "name": "facebook---主页+关注1000次/0掉落/45天自动补充",
              "price": "1.3",
              "sfcategory_id": 43,
              "deletetime": null
            },
            {
              "id": 215,
              "name": "facebook---评论赞1000次/(通道一)",
              "price": "1.9",
              "sfcategory_id": 43,
              "deletetime": null
            },
            {
              "id": 216,
              "name": "facebook---主页赞1000次/(通道一)",
              "price": "1.9",
              "sfcategory_id": 43,
              "deletetime": null
            }
          ]
        },
        {
          label:'微信相关',
          children: [
            {
              "id": 106,
              "name": "公众号粉丝关注100个",
              "price": "0.55",
              "sfcategory_id": 44,
              "deletetime": null
            },
            {
              "id": 113,
              "name": "微信投票50个",
              "price": "0.5",
              "sfcategory_id": 44,
              "deletetime": null
            },
            {
              "id": 225,
              "name": "公众号文章20个赞",
              "price": "1.0",
              "sfcategory_id": 44,
              "deletetime": null
            },
            {
              "id": 226,
              "name": "公众号文章50个赞",
              "price": "2.0",
              "sfcategory_id": 44,
              "deletetime": null
            },
            {
              "id": 2083,
              "name": "微信视频号播放1000+",
              "price": "5.0",
              "sfcategory_id": 44,
              "deletetime": null
            },
            {
              "id": 2084,
              "name": "微信视频号点赞1000个",
              "price": "5.0",
              "sfcategory_id": 44,
              "deletetime": null
            },
            {
              "id": 2085,
              "name": "微信视频号关注1000个",
              "price": "5.0",
              "sfcategory_id": 44,
              "deletetime": null
            }
          ]
        },
        {
          label:'微博',
          children:[
            {
              "id": 107,
              "name": "随机粉丝1000个",
              "price": "0.5",
              "sfcategory_id": 47,
              "deletetime": null
            },
            {
              "id": 108,
              "name": "老号粉丝关注1000个",
              "price": "1.25",
              "sfcategory_id": 47,
              "deletetime": null
            },
            {
              "id": 109,
              "name": "博文点赞100个",
              "price": "0.05",
              "sfcategory_id": 47,
              "deletetime": null
            },
            {
              "id": 110,
              "name": "博文转发100次",
              "price": "0.3",
              "sfcategory_id": 47,
              "deletetime": null
            },
            {
              "id": 111,
              "name": "博文100阅读量",
              "price": "0.05",
              "sfcategory_id": 47,
              "deletetime": null
            },
            {
              "id": 112,
              "name": "博文评论20个（备注填写评论）",
              "price": "0.5",
              "sfcategory_id": 47,
              "deletetime": null
            }
          ]
        },
        {
          label:'Telegram电报-频道以前的帖子增加浏览量、表情',
          children: [
            {
              "id": 114,
              "name": "Telegram-频道增加阅读量1000次——最近发布的1条帖子 (通道3) ",
              "price": "0.01",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 144,
              "name": "Telegram-频道增加阅读量1000次——最近发布的1条帖子 (通道1) ",
              "price": "0.01",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 145,
              "name": "Telegram-频道增加阅读量1000次——最近发布的1条帖子 (通道4) ",
              "price": "0.01",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 146,
              "name": "Telegram-频道增加阅读量1000次——最近发布的1条帖子 (通道2) ",
              "price": "0.01",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 147,
              "name": "Telegram-频道增加阅读量1000次——最近发布的5条帖子",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 148,
              "name": "Telegram-频道增加阅读量1000次——最近发布的10条帖子",
              "price": "0.03",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 149,
              "name": "Telegram-频道增加阅读量1000次——最近发布的20条帖子",
              "price": "0.05",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 150,
              "name": "Telegram-频道增加阅读量1000次——最近发布的50条帖子",
              "price": "0.11",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 151,
              "name": "Telegram-频道增加阅读量1000次——最近发布的100条帖子",
              "price": "0.15",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 152,
              "name": "telegram频道帖子混合积极表情+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 153,
              "name": "telegram频道帖子混合负面表情+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 154,
              "name": "telegram频道帖子表情codea+免费浏览量1000次 ",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 155,
              "name": "telegram频道帖子表情codeb+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 156,
              "name": "telegram频道帖子表情codec+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 157,
              "name": "telegram频道帖子表情coded+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 158,
              "name": "telegram频道帖子表情codee+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 159,
              "name": "telegram频道帖子表情codef+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 160,
              "name": "telegram频道帖子表情codeg+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 161,
              "name": "telegram频道帖子表情codeh+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 162,
              "name": "telegram频道帖子表情codei+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 163,
              "name": "telegram频道帖子表情codej+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 164,
              "name": "telegram频道帖子表情codek+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 165,
              "name": "telegram频道帖子表情codel+免费浏览量1000次",
              "price": "0.02",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 166,
              "name": "telegram频道帖子英文随机评论1000次",
              "price": "1.5",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 167,
              "name": "telegram频道帖子自定义随机评论1000次(下单后发评论给客服)",
              "price": "8.5",
              "sfcategory_id": 48,
              "deletetime": null
            },
            {
              "id": 168,
              "name": "telegram电子投票1000次",
              "price": "0.15",
              "sfcategory_id": 48,
              "deletetime": null
            }
          ]
        },
        {
          label:'Telegram电报-频道权重优化+免费浏览（上升全局搜索排名）',
          children: [
            {
              "id": 169,
              "name": "Telegram电报-全局排名 全面智能优化1000次 (随机国家) ",
              "price": "0.12",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 170,
              "name": "Telegram电报-全局排名 全面智能优化 1000次(中国) ",
              "price": "0.45",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 171,
              "name": "Telegram电报-全局排名 全面智能优化1000次 (美国) ",
              "price": "0.45",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 172,
              "name": "Telegram电报-全局排名 全面智能优化1000次 (俄罗斯) ",
              "price": "0.45",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 173,
              "name": "Telegram电报-全局排名 全面智能优化1000次 (印度) ",
              "price": "0.45",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 174,
              "name": "Telegram电报-全局排名 全面智能优化1000次 (意大利)",
              "price": "0.45",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 175,
              "name": "Telegram电报-全局排名 全面智能优化1000次 (印度尼西亚) ",
              "price": "0.45",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 176,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（随机国家）",
              "price": "0.01",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 177,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（中国）",
              "price": "0.15",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 178,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（美国）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 179,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（俄罗斯）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 180,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（印度）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 181,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（英国）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 182,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（巴西）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 183,
              "name": "Telegram电报-频道帖子静态ip浏览1000次（德国）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 184,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（随机国家）",
              "price": "0.02",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 185,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（中国）",
              "price": "0.03",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 186,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（美国）",
              "price": "0.04",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 187,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（俄罗斯）",
              "price": "0.04",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 188,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（巴西）",
              "price": "0.03",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 189,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（印度）",
              "price": "0.03",
              "sfcategory_id": 49,
              "deletetime": null
            },
            {
              "id": 190,
              "name": "Telegram电报-频道帖子分享+免费浏览1000次（德国）",
              "price": "0.03",
              "sfcategory_id": 49,
              "deletetime": null
            }
          ]
        },
        {
          label:'小红书真人粉丝',
          children: [
            {
              "id": 122,
              "name": "小红书真人粉丝100个",
              "price": "2.0",
              "sfcategory_id": 50,
              "deletetime": null
            }
          ]
        },
        {
          label:'小红书真人点赞',
          children: [
            {
              "id": 123,
              "name": "小红书真人点赞100个",
              "price": "1.0",
              "sfcategory_id": 51,
              "deletetime": null
            }
          ]
        },
        {
          label:'小红书小眼睛阅读',
          children: [
            {
              "id": 124,
              "name": "小红书小眼睛阅读100个",
              "price": "0.1",
              "sfcategory_id": 52,
              "deletetime": null
            }
          ]
        },
        {
          label:'小红书作品评论',
          children: [
            {
              "id": 125,
              "name": "小红书作品评论10个",
              "price": "0.25",
              "sfcategory_id": 53,
              "deletetime": null
            }
          ]
        },
        {
          label:'Instagram',
          children: [
            {
              "id": 126,
              "name": "instagram粉丝1000个(国家随机)",
              "price": "3.0",
              "sfcategory_id": 54,
              "deletetime": null
            },
            {
              "id": 127,
              "name": "instagram粉丝1000个(私聊客服指定国家)",
              "price": "3.5",
              "sfcategory_id": 54,
              "deletetime": null
            },
            {
              "id": 128,
              "name": "instagram点赞500个",
              "price": "0.15",
              "sfcategory_id": 54,
              "deletetime": null
            },
            {
              "id": 129,
              "name": "instagram评论1000个（评论内容发给客服）",
              "price": "2.5",
              "sfcategory_id": 54,
              "deletetime": null
            }
          ]
        },
        {
          label:'百度贴吧',
          children: [
            {
              "id": 130,
              "name": "百度贴吧帖子点赞20个",
              "price": "0.2",
              "sfcategory_id": 55,
              "deletetime": null
            },
            {
              "id": 224,
              "name": "百度贴吧帖子评论10个（备注填写评论）",
              "price": "1.0",
              "sfcategory_id": 55,
              "deletetime": null
            }
          ]
        },
        {
          label:'Youtube视频/短片播放量',
          children: [
            {
              "id": 132,
              "name": "youtube视频/短片播放量1000次",
              "price": "1.75",
              "sfcategory_id": 56,
              "deletetime": null
            }
          ]
        },
        {
          label:'Youtube蓝V',
          children: [
            {
              "id": 54,
              "name": "youtube蓝V",
              "price": "5",
              "sfcategory_id": 25,
              "deletetime": null
            }
          ]
        },
        {
          label:'Youtube视频/短片评论',
          children: [
            {
              "id": 133,
              "name": "youtube随机国家随机评论1000个",
              "price": "2.0",
              "sfcategory_id": 57,
              "deletetime": null
            },
            {
              "id": 134,
              "name": "youtube指定国家指定评论1000个",
              "price": "3.0",
              "sfcategory_id": 57,
              "deletetime": null
            }
          ]
        },
        {
          label:'Youtube点赞',
          children: [
            {
              "id": 131,
              "name": "youtube点赞1000个",
              "price": "1.0",
              "sfcategory_id": 58,
              "deletetime": null
            }
          ]
        },
        {
          label:'Youtube订阅',
          children: [
              {
            "id": 135,
            "name": "youtube粉丝订阅1000个",
            "price": "2.25",
            "sfcategory_id": 59,
            "deletetime": null
          }]
        },
        {
          label:'Telegram电报-开通会员',
          children: [
            {
              "id": 136,
              "name": "Telegram电报大会员(12个月)",
              "price": "25.0",
              "sfcategory_id": 60,
              "deletetime": null
            },
            {
              "id": 137,
              "name": "Telegram电报大会员(6个月)",
              "price": "16.0",
              "sfcategory_id": 60,
              "deletetime": null
            },
            {
              "id": 138,
              "name": "Telegram电报大会员(3个月)",
              "price": "9.0",
              "sfcategory_id": 60,
              "deletetime": null
            }
          ]
        },
        {
          label:'Telegram电报-最便宜的僵尸粉',
          children: [
            {
              "id": 139,
              "name": "Telegram电报-频道僵尸粉1000人",
              "price": "0.23",
              "sfcategory_id": 61,
              "deletetime": null
            },
            {
              "id": 212,
              "name": "Telegram电报-群组僵尸粉1000人",
              "price": "0.23",
              "sfcategory_id": 61,
              "deletetime": null
            }
          ]
        },
        {
          label:'Telegram电报-极速粉',
          children: [
            {
              "id": 140,
              "name": "Telegram电报-伊朗粉丝1000个 (群主号必须为+98伊朗号码) 50k/天 ，20-40%掉落",
              "price": "0.5",
              "sfcategory_id": 62,
              "deletetime": null
            },
            {
              "id": 141,
              "name": "Telegram电报-极速粉1000个(超高速)速度60K/天，20-40%掉落",
              "price": "0.65",
              "sfcategory_id": 62,
              "deletetime": null
            },
            {
              "id": 142,
              "name": "Telegram电报-极速粉1000个(通道一)速度30K/天，10-20%掉落",
              "price": "0.45",
              "sfcategory_id": 62,
              "deletetime": null
            },
            {
              "id": 143,
              "name": "Telegram电报-极速粉1000个(0掉落)速度10-20K/天",
              "price": "0.9",
              "sfcategory_id": 62,
              "deletetime": null
            },
            {
              "id": 213,
              "name": "Telegram电报-极速粉1000个(粉丝昵称中文)速度30K/天，10-20%掉落",
              "price": "0.55",
              "sfcategory_id": 62,
              "deletetime": null
            },
            {
              "id": 214,
              "name": "Telegram电报-极速粉1000个(粉丝昵称英文)速度30K/天，10-20%掉落",
              "price": "0.45",
              "sfcategory_id": 62,
              "deletetime": null
            }
          ]
        },
        {
          label:'Telegram电报-在线粉',
          children: [
            {
              "id": 220,
              "name": "telegram在线粉丝100",
              "price": "0.3",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "telegram在线粉丝500",
              "price": "1.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "telegram在线粉丝1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "telegram在线粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Facebook群组粉丝',
          children: [
            {
              "id": 194,
              "name": "facebook---群组粉丝1000个♻️30天补充（通道一）",
              "price": "1.25",
              "sfcategory_id": 63,
              "deletetime": null
            },
            {
              "id": 195,
              "name": "facebook---群组粉丝1000个♻️30天补充（通道二）",
              "price": "1.25",
              "sfcategory_id": 63,
              "deletetime": null
            },
            {
              "id": 196,
              "name": "facebook---群组粉丝1000个♻️30天补充（通道三）",
              "price": "1.25",
              "sfcategory_id": 63,
              "deletetime": null
            }
          ]
        },
        {
          label:'Twitter推特关注',
          children: [
            {
              "id": 197,
              "name": "推特关注1000粉，有掉落无补充/10K每天/低速掉落",
              "price": "0.35",
              "sfcategory_id": 64,
              "deletetime": null
            },
            {
              "id": 198,
              "name": "推特关注1000粉，30天内掉粉免费补充/5K每天/低速掉落",
              "price": "0.5",
              "sfcategory_id": 64,
              "deletetime": null
            },
            {
              "id": 199,
              "name": "推特关注1000粉，30天内掉粉免费补充/20K每天/很少掉落或者不掉",
              "price": "0.52",
              "sfcategory_id": 64,
              "deletetime": null
            }
          ]
        },
        {
          label:'Twitter推特点赞',
          children: [
            {
              "id": 200,
              "name": "Twitter 推特点赞1000个 无补充/10k每天/少量掉落",
              "price": "0.5",
              "sfcategory_id": 65,
              "deletetime": null
            },
            {
              "id": 201,
              "name": "Twitter 推特点赞1000个（高质量）无补充/10k每天/少量掉落",
              "price": "0.75",
              "sfcategory_id": 65,
              "deletetime": null
            },
            {
              "id": 202,
              "name": "Twitter 推特点赞1000个,30天补充/10k每天/少量掉落 ",
              "price": "1.0",
              "sfcategory_id": 65,
              "deletetime": null
            }
          ]
        },
        {
          label:'Twitter推特转推',
          children: [
              {
            "id": 203,
            "name": "Twitter 推特转推1000次  20K每天/低掉落无补充",
            "price": "0.75",
            "sfcategory_id": 66,
            "deletetime": null
          },
            {
              "id": 204,
              "name": "Twitter 推特转推1000次  100-300每天/0掉落",
              "price": "0.85",
              "sfcategory_id": 66,
              "deletetime": null
            }
          ]
        },
        {
          label:'Facebook脸书评论',
          children: [
            {
              "id": 205,
              "name": "facebook评论 5星+随机内容1000条",
              "price": "5.0",
              "sfcategory_id": 67,
              "deletetime": null
            },
            {
              "id": 206,
              "name": "facebook评论 5星+自定义内容1000条",
              "price": "7.5",
              "sfcategory_id": 67,
              "deletetime": null
            }
          ]
        },
        {
          label:'Bilibili/b站粉丝关注',
          children: [
            {
              "id": 207,
              "name": "b站粉丝关注1000人",
              "price": "0.5",
              "sfcategory_id": 68,
              "deletetime": null
            }
          ]
        },
        {
          label:'QQ群拉僵尸',
          children: [
            {
              "id": 208,
              "name": "QQ群拉假人200人",
              "price": "0.05",
              "sfcategory_id": 69,
              "deletetime": null
            },
            {
              "id": 209,
              "name": "QQ群拉假人500人",
              "price": "0.1",
              "sfcategory_id": 69,
              "deletetime": null
            },
            {
              "id": 210,
              "name": "QQ群拉假人2000人",
              "price": "0.35",
              "sfcategory_id": 69,
              "deletetime": null
            }
          ]
        },
        {
          label:'Line公众号粉丝',
          children: [
            {
              "id": 211,
              "name": "Line公众号粉丝1000人",
              "price": "15.0",
              "sfcategory_id": 70,
              "deletetime": null
            }
          ]
        },
        {
          label:'美团点赞',
          children: [
            {
              "id": 217,
              "name": "美团点赞-100个",
              "price": "2.0",
              "sfcategory_id": 71,
              "deletetime": null
            }
          ]
        },
        {
          label:'美团好/差评',
          children: [
            {
              "id": 218,
              "name": "美团差评-20个",
              "price": "5.0",
              "sfcategory_id": 72,
              "deletetime": null
            },
            {
              "id": 219,
              "name": "美团好评-20个",
              "price": "5.0",
              "sfcategory_id": 72,
              "deletetime": null
            }
          ]
        },
        {
          label:'抖音评论点赞',
          children: [
            {
              "id": 220,
              "name": "抖音评论点赞10个",
              "price": "0.02",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "抖音评论点赞50个",
              "price": "0.06",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "抖音评论点赞100个",
              "price": "0.1",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "抖音评论点赞500个",
              "price": "0.4",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Ins视频浏览量',
          children: [
            {
              "id": 220,
              "name": "ins视频浏览量10",
              "price": "0.03",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "ins视频浏览量50",
              "price": "0.12",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "ins视频浏览量100",
              "price": "0.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "ins视频浏览量500",
              "price": "0.9",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'微信公众号刷浏览量',
          children: [
            {
              "id": 220,
              "name": "微信公众号浏览量10",
              "price": "0.03",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "微信公众号浏览量50",
              "price": "0.12",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "微信公众号浏览量100",
              "price": "0.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "微信公众号浏览量500",
              "price": "0.9",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Whatsapp刷粉',
          children: [
            {
              "id": 220,
              "name": "whatsapp粉丝10",
              "price": "0.03",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "whatsapp粉丝50",
              "price": "0.12",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "whatsapp粉丝100",
              "price": "0.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "whatsapp粉丝500",
              "price": "0.9",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Whatsapp拉人',
          children: [
            {
              "id": 220,
              "name": "whatsapp拉人100",
              "price": "0.3",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "whatsapp粉丝500",
              "price": "1.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "whatsapp粉丝1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "whatsapp粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'TT语音粉丝',
          children: [
            {
              "id": 220,
              "name": "TT语音在线粉丝100",
              "price": "0.3",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "TT语音在线粉丝500",
              "price": "1.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "TT语音在线粉丝1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "TT语音在线粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "TT语音僵尸粉1000",
              "price": "1",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Kakao粉丝',
          children: [
            {
              "id": 220,
              "name": "Kakao在线粉丝100",
              "price": "0.3",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "Kakao在线粉丝500",
              "price": "1.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "Kakao在线粉丝1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "Kakao在线粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "Kakao僵尸粉1000",
              "price": "1",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'Potato粉丝',
          children: [
            {
              "id": 220,
              "name": "potato在线粉丝100",
              "price": "0.5",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "potato在线粉丝500",
              "price": "1.5",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "potato在线粉丝1000",
              "price": "2.8",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "potato在线粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "potato僵尸粉1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 224,
              "name": "potato浏览量1000",
              "price": "0.5",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        },
        {
          label:'OKC粉丝',
          children: [
            {
              "id": 220,
              "name": "OKC在线粉丝100",
              "price": "0.3",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 221,
              "name": "OKC在线粉丝500",
              "price": "1.2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 222,
              "name": "OKC在线粉丝1000",
              "price": "2",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "OKC在线粉丝5000",
              "price": "9",
              "sfcategory_id": 73,
              "deletetime": null
            },
            {
              "id": 223,
              "name": "OKC僵尸粉1000",
              "price": "1",
              "sfcategory_id": 73,
              "deletetime": null
            }
          ]
        }
      ],
      tableData: []
    }
  },
  created() {

  },
  methods:{
      handleEdit(index,row){
      console.log(index,row)
        this.$router.push('/order');
      },
    getTableList(item){
      console.log(item,'itemitemitem');
      this.tableData = item.children;
    }
    }
};
</script>
<style>
.simu-dropdown{
  height: 500px;
  background-color: #1D1E1F !important;
  overflow-y: scroll;
  border: 1px solid #414243;
  color: #CDCFD7;
}

.simu-dropdown .el-dropdown-menu__item:hover {
  background-color: #1A222C;
}
</style>
<style scoped>
.el-card{
  width: 90%;
  margin-left: 5%;
  background: #373539;
  border: 1px solid #414243;
}
::v-deep .el-input__inner{
  color: #CACDD5;
  background-color: #373539 !important;
  border:1px solid #4C4D4F;
  border-right: none;
}
::v-deep .el-input__inner::-webkit-input-placeholder{
  color: #85878C;
}
::v-deep .el-input-group__append, .el-input-group__prepend{
  background-color: #373539 !important;
  border:1px solid #4C4D4F;
  border-left: none;
}
::v-deep .el-table th.el-table__cell{
  color: #A4A6AC;
  background-color: #141414 !important;
}
::v-deep .el-table tr{
  color: #D0D3DB;
  background-color: #373539 !important;
}
::v-deep .el-table th.el-table__cell.is-leaf{
  border-bottom: 1px solid #373539 !important;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
  background-color: #1D1D1D !important;
}
::v-deep .el-table td.el-table__cell{
  border-bottom: 1px solid #373539 !important;
}
::v-deep .el-table::before{
  background-color:#373539 ;
}
::v-deep .el-table tbody tr:hover > td{
  background-color: #262626 !important;
}
::v-deep .el-table__empty-block{
  background-color: #373539 !important;
}
</style>
