<template>
  <div>
    <div class="text-h-v-center">
      <h1>服务条款</h1>
    </div>
    <div class="card-position">注册或使用我们的服务即表示您同意您已阅读并完全理解以下服务条款，对于未阅读以下服务条款的用户，Telead 将不承担任何形式的损失。 </div>
    <div style="margin-top: 20px;">
      <el-card class="box-card" shadow="always" >
       <h3>重要规则</h3>
        <p>1、您只能以遵循与 Instagram/facebook/twitter/youtube/其他社交媒体网站在其各自的服务条款页面上达成的所有协议的方式使用该网站。</p>
        <p>2、企鹅刷粉将仅用于宣传您的 Telegram/Instagram/Twitter/Facebook 和其他社交资料以进行个人参与并提高您的社交效率。</p>
        <p>3、不要同时为 相同的链接 使用两个服务。如果您这样做，我们将无法修复它。等待第一个订单完成，然后为同一链接下新订单。</p>
        <p>4、在我们的面板中向帐户收费后无法退款。</p>
        <p>5、仅使用门票部分以获得支持。</p>
        <p>6、开始后无法取消订单。除非你有充分的理由。</p>
        <p>7、如果用户在注册后超过 3 个月没有任何订单/存款，我们将终止帐户，恕不另行通知。</p>
        <p>8、企鹅刷粉不保证任何服务的交货时间。我们对何时交付订单提供最佳估计。</p>
      </el-card>
    </div>
    <div style="margin-top: 20px;">
      <el-card class="box-card" shadow="always" >
        <h3>隐私政策</h3>
        <p>本页向您介绍我们在您使用我们的服务时收集、使用和披露个人数据的政策，以及您与该数据相关的选择。</p>
        <p style="line-height: 20px;">我们使用您的数据来提供和改进服务。使用本服务，即表示您同意按照本政策收集和使用信息。除非本隐私政策中另有定义，否则本隐私政策中使用的术语与我们的条款和条件中的含义相同，可从 https://xxxxxxxxxxxxxxxxx/ 访问</p>
      </el-card>
    </div>
    <div style="margin-top: 20px;">
      <el-card class="box-card" shadow="always" >
        <h3>跟踪和 Cookie 数据</h3>
        <p>我们使用 cookie 和类似的跟踪技术来跟踪我们服务上的活动并保存某些信息。</p>
        <p style="line-height: 20px;">Cookie 是包含少量数据的文件，其中可能包含匿名唯一标识符。Cookie 从网站发送到您的浏览器并存储在您的设备上。还使用的跟踪技术是信标、标签和脚本，用于收集和跟踪信息以及改进和分析我们的服务。</p>
      </el-card>
    </div>
    <div style="margin-top: 20px;">
      <el-card class="box-card" shadow="always" >
        <h3>数据的使用</h3>
        <p>企鹅刷粉将收集到的数据用于各种目的</p>
        <ul>
          <li>提供和维护服务</li>
          <li>通知您有关我们服务的更改</li>
          <li>允许您在选择时参与我们服务的互动功能</li>
          <li>供客户关怀和支持</li>
          <li>提供分析或有价值的信息，以便我们改进服务</li>
          <li>监控服务的使用情况</li>
          <li>检测、预防和解决技术问题</li>
        </ul>
      </el-card>
    </div>
    <div style="margin-top: 20px;">
      <el-card class="box-card" shadow="always" >
        <h3>企鹅刷粉能会真诚地披露您的个人数据，认为此类行动对于以下方面是必要的：</h3>
        <ul>
          <li>遵守法律义务</li>
          <li>保护和捍卫 telead.cc 的权利或财产</li>
          <li>防止或调查与服务有关的可能的不当行为</li>
          <li>保护服务使用者或公众的人身财产安全</li>
        </ul>
      </el-card>
    </div>
    <div style="margin-top: 20px;margin-bottom: 20px;">
      <el-card class="box-card" shadow="always" >
        <h3>数据安全与隐私政策</h3>
        <p style="line-height: 20px;">您数据的安全性对我们很重要，但请记住，没有任何一种通过 Internet 传输的方法或电子存储方法是 100% 安全的。虽然我们努力使用商业上可接受的方式来保护您的个人数据。</p>
        <p>我们可能会不时更新我们的隐私政策。我们将通过在此页面上发布新的隐私政策来通知您任何更改。</p>
        <p>在更改生效之前，我们将通过电子邮件和/或我们服务上的醒目通知通知您，并更新本隐私政策顶部的“生效日期”。</p>
        <p>建议您定期查看本隐私政策以了解任何更改。本隐私政策的更改一经发布在此页面上即生效。</p>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
    }
  },
  methods:{

  }
};
</script>
<style scoped>
.el-card{
  width: 90%;
  margin-left: 5%;
  background: #373539;
  border: 1px solid #414243;
  color: #E6EAF2;
}
.el-card p{
  font-size: 13px;
}
.el-card ul li{
  font-size: 13px;
}
.text-h-v-center{
  display: grid;
  place-items: center;
}
.card-position{
  width: 90%;
  margin: 22px auto auto;
  line-height: 24px;
}
</style>
