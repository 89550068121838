<template>
  <div>
    <giveService />
  </div>
</template>
<script>
import giveService from "@/views/mall/giveService.vue";
export default {
  components: {
    giveService,
  },
};
</script>
