import VueRouter from 'vue-router';
import Login from '@/views/login/index.vue';
import Home from '@/views/home/index.vue';
import About from '@/views/about/index.vue';
import Mall from '@/views/mall/index.vue';
import LoggedInMall from '@/views/loggedInMall/index.vue';
import ShopMall from '@/views/loggedInMall/views/index.vue';
// 下单
import Order from '@/views/loggedInMall/views/order.vue';
// 我的订单
import Orders from '@/views/loggedInMall/views/orders.vue';
// 充值
import Recharge from '@/views/loggedInMall/views/recharge.vue';
// 代理
import Agent from '@/views/loggedInMall/views/agent.vue';
// 服务条款
import Aboutb from '@/views/loggedInMall/views/aboutb.vue';
// api
import Api from '@/views/loggedInMall/views/api.vue';
const routes = [
  {
    path: '/',
    component: Login,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
      },
      {
        path: '/mall',
        component: Mall,
      },
      {
        path: '/about',
        name: 'About',
        component: About,
      },
    ],
  },
  {
    path: '/shops',
    component: LoggedInMall,
    children: [
      {
        path: '/shopMall',
        component: ShopMall,
      },
      {
        path: '/order',
        component: Order,
      },
      {
        path: '/orders',
        component: Orders,
      },
      {
        path: '/recharge',
        component: Recharge,
      },
      {
        path: '/agent',
        component: Agent,
      },
      {
        path: '/aboutb',
        component: Aboutb,
      },
      {
        path: '/api',
        component: Api,
      },
    ],
  },
];
export const router = new VueRouter({
  routes, // (缩写) 相当于 routes: routes
});
const witeRoutes = ['/home', '/mall', '/about'];
router.beforeEach((to, from, next) => {
  if (!witeRoutes.includes(to.path)) {
    const isLogin = localStorage.getItem('isLogin');
    console.log(isLogin == true, 'isLogin');
    if (isLogin == 'true') {
      next();
    } else {
      next('/home');
    }
  } else {
    next();
  }
});
