<template>
<div>
  <el-row :gutter="20">
    <el-col :xs="24" :sm="12"  :lg="6" :xl="4">
      <el-card class="box-cardList" shadow="always" >
        <div>
          <div class="IconLists">
            <i class="el-icon-user-solid" style="font-size: 50px;padding: 10px 30px;"></i>
          </div>
        </div>
        <div class="ContentLists" style="">
          <div style="padding-bottom: 10px;">{{userListInfo.userName}}</div>
          <div style="padding-top: 10px;">欢迎老板发大财</div>
        </div>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12"  :lg="6" :xl="4">
      <el-card class="box-cardList" shadow="always" >
        <div>
          <div class="IconLists">
            <i class="el-icon-tickets" style="font-size: 50px;padding: 10px 30px;"></i>
          </div>
        </div>
        <div class="ContentLists">
          <div style="padding-bottom: 10px;">¥0.00</div>
          <div style="padding-top: 10px;">消费金额</div>
        </div>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12"  :lg="6" :xl="4">
      <el-card class="box-cardList" shadow="always" >
        <div>
          <div class="IconLists">
            <i class="el-icon-document-copy" style="font-size: 50px;padding: 10px 30px;"></i>
          </div>
        </div>
        <div class="ContentLists">
          <div style="padding-bottom: 10px;">0</div>
          <div style="padding-top: 10px;">已完成订单</div>
        </div>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12"  :lg="6" :xl="4">
      <el-card class="box-cardList" shadow="always" >
        <div>
          <div class="IconLists">
            <i class="el-icon-medal-1" style="font-size: 50px;padding: 10px 30px;"></i>
          </div>
        </div>
        <div class="ContentLists">
          <div style="padding-bottom: 10px;">¥0.00</div>
          <div style="padding-top: 10px;">账户余额</div>
        </div>
      </el-card>
    </el-col>
  </el-row>
</div>
</template>

<script>
export default {
  data() {
    return {
      userListInfo:{}
    }
  },
  created() {
    this.userListInfo = JSON.parse(localStorage.getItem('userListInfos'))
  },
}
</script>

<style>
.box-cardList .el-card__body{
  display: flex;
  align-items: center;
}
</style>
<style scoped>
.box-cardList{
  background: #373539;
  border: 1px solid #414243;
  margin-top: 10px;
}

.IconLists{
  color: #ff8355;
  background: #493E3C;
  border-radius: 10px;
}
.ContentLists{
  color:#E6EAF2;
  padding-left: 10px;
}
</style>
