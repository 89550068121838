<template>
  <div>
    <topBoxs></topBoxs>
    <div class="listTitle">
      <span>关注频道，获取最快上粉</span>
      <el-button type="danger" size="small" style="margin-left: 7px;">关注频道</el-button>
    </div>
    <div>
      <giveService></giveService>
    </div>
  </div>
</template>
<script>
import giveService from "@/views/mall/giveService.vue";
import topBoxs from "@/views/loggedInMall/components/topBoxs.vue";
export default {
  components: {
    giveService,
    topBoxs
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods:{
  }
};
</script>

<style scoped>
.listTitle{
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
