<template>
  <el-header class="heasder">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      text-color="#fff"
      background-color="#252427"
      active-text-color="#409eff"
      router
      @select="handleSelect"
    >
      <el-menu-item index="home" style="margin-left: 5%">国际刷粉</el-menu-item>
      <div style="flex: 1;line-height: 60px;color: #7FCDFA;cursor: pointer;" @click="getUser"><i class="el-icon-s-custom"></i>在线客服</div>
      <el-menu-item index="/home">首页</el-menu-item>
      <el-menu-item index="/mall">商城</el-menu-item>
      <el-menu-item index="/about" style="margin-right: 1rem"
        >关于</el-menu-item
      >
    </el-menu>
  </el-header>
</template>
<script>
export default {
  data() {
    return {
      activeIndex: this.$route.path,
    };
  },
  watch: {
    '$route.path'(val) {
      if (val !== this.activeIndex) {
        this.activeIndex = val;
      }
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    getUser(){
      window.location.href = "https://t.me/xincai8888"
    }
  },
};
</script>
<style scoped lang="less">
/deep/.el-menu-item {
  font-size: 18px;
}
/deep/.el-menu.el-menu--horizontal {
  border-color: #4c4d4f;
}
.heasder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(55, 53, 57);
  align-items: center;
  padding: 0;
  .el-menu-demo {
    width: 100%;
    display: flex;
    background-color: rgb(55, 53, 57);
    color: #fff;
  }
}
</style>
