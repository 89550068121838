<template>
  <div>
    <conditions />
  </div>
</template>
<script>
import conditions from "@/views/about/conditions.vue";
export default {
  components: {
    conditions,
  },
};
</script>
