<template>
  <div>
    <topBoxs></topBoxs>
    <el-tabs v-model="activeName">
    <el-tab-pane v-for="(item,index) in dataLists" :key="index" :label="item.text" :name="item.value">
      <div style="margin-top: 4px;">
      <el-card class="box-cardTwoList" shadow="always" >
        <el-input placeholder="搜索" v-model="searchValue">
          <template slot="append">
            <i class="el-icon-search"></i>
          </template>
        </el-input>
        </el-card>
        <el-card class="box-cardTwoList" shadow="always" >
          <el-table
              :data="tableData"
              stripe
              style="width: 100%;background-color: #666 !important;">
            <el-table-column
                prop="id"
                label="编号">
            </el-table-column>
            <el-table-column
                prop="name"
                label="日期"
            >
            </el-table-column>
            <el-table-column
                prop="price"
                label="费用">
            </el-table-column>
            <el-table-column
                prop="price"
                label="开始计数">
            </el-table-column>
            <el-table-column
                prop="price"
                label="数量">
            </el-table-column>
            <el-table-column
                prop="price"
                label="订单服务">
            </el-table-column>
            <el-table-column
                prop="price"
                label="订单状态">
            </el-table-column>
            <el-table-column
                prop="price"
                label="剩余">
            </el-table-column>
<!--            <el-table-column-->
<!--                width="80"-->
<!--                align="right">-->
<!--              <template slot="header"></template>-->
<!--              <template slot-scope="scope">-->
<!--                <el-button-->
<!--                    type="danger"-->
<!--                    size="mini"-->
<!--                    @click="handleEdit(scope.$index, scope.row)">详情</el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </el-card>
        </div>
    </el-tab-pane>
  </el-tabs>
  </div>
</template>
<script>
import topBoxs from "@/views/loggedInMall/components/topBoxs.vue";
export default {
  components: {
    topBoxs
  },
  data() {
    return {
      searchValue:'',
      activeName:'1',
      tableData:[],
      dataLists:[
        {
          text:'所有',
          value:'1'
        },
        {
          text:'等待',
          value:'2'
        },
        {
          text:'进行中',
          value:'3'
        },
        {
          text:'完成',
          value:'4'
        },
        {
          text:'部分(已退余款)',
          value:'5'
        },
        {
          text:'处理',
          value:'6'
        },
        {
          text:'取消',
          value:'7'
        }
      ],
    }
  },
  created() {

  },
  methods:{
  }
};
</script>

<style scoped>
.box-cardTwoList{
  background: #373539;
  border: 1px solid #414243;
  margin-top: 10px;
}
::v-deep .el-tabs__item{
  color: #ffffff;
}
::v-deep .el-tabs__item.is-active{
  color: #EF8A60;
}
::v-deep .el-input__inner{
  color: #CACDD5;
  background-color: #373539 !important;
  border:1px solid #4C4D4F;
  border-right: none;
}
::v-deep .el-input__inner::-webkit-input-placeholder{
  color: #85878C;
}
::v-deep .el-input-group__append, .el-input-group__prepend{
  background-color: #373539 !important;
  border:1px solid #4C4D4F;
  border-left: none;
  color: #EF8A60;
}
::v-deep .el-table th.el-table__cell{
  color: #A4A6AC;
  background-color: #141414 !important;
}
::v-deep .el-table tr{
  color: #D0D3DB;
  background-color: #373539 !important;
}
::v-deep .el-table th.el-table__cell.is-leaf{
  border-bottom: 1px solid #373539 !important;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
  background-color: #1D1D1D !important;
}
::v-deep .el-table td.el-table__cell{
  border-bottom: 1px solid #373539 !important;
}
::v-deep .el-table::before{
  background-color:#373539 ;
}
::v-deep .el-table tbody tr:hover > td{
  background-color: #262626 !important;
}
::v-deep .el-table__empty-block{
  background-color: #373539 !important;
}
</style>
