<style scoped lang="less">
.headers {
  width: 100%;
  display: flex;
  justify-content: right;
  background-color: rgb(55, 53, 57);
  align-items: center;
  .buttons {
    // display: flex;
    // gap: 20px;
  }
}
/deep/.el-menu {
  background: transparent;
  border-color: #4c4d4f;
  height: calc(100vh - 60px) !important;
}
/deep/.el-menu-item {
  color: #fff;
}
</style>
<template>
  <el-container>
    <el-header class="headers">
      <div style="flex: 1;line-height: 60px;color: #7FCDFA;cursor: pointer;" @click="getUser"><i class="el-icon-s-custom"></i>在线客服</div>
      <div class="buttons">
        <el-button type="danger"> ¥0.00 </el-button>
        <el-button type="warning" style="margin: 0 20px" @click="goBack">
          退出
        </el-button>
        <el-dropdown @command="handleCommand">
          <el-button type="danger">
            菜单<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              :command="item.index"
              v-for="item in navList"
              :key="item.index"
              >{{ item.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <el-aside width="100px" v-if="screenWidth > 900">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          router
          @select="handleSelect"
          text-color="#fff"
          background-color="#252427"
          active-text-color="#ff8355"
        >
          <el-menu-item
            v-for="item in navList"
            :key="item.index"
            :index="item.index"
            >{{ item.name }}</el-menu-item
          >
        </el-menu>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
    <Footer></Footer>
  </el-container>
</template>

<script>
import Footer from '@/components/Footer.vue';
export default {
  components: {
    Footer
  },
  data() {
    return {
      activeIndex: this.$route.path,
      screenWidth: window.innerWidth,
      navList: [
        {
          name: '商城',
          index: '/shopMall',
        },
        {
          name: '下单',
          index: '/order',
        },
        {
          name: '我的订单',
          index: '/orders',
        },
        {
          name: '充值',
          index: '/recharge',
        },
        // {
        //   name: '加入代理',
        //   index: '/agent',
        // },
        {
          name: '服务条款',
          index: '/aboutb',
        },
        {
          name: 'api接口',
          index: '/api',
        },
      ],
    };
  },
  watch: {
    '$route.path'(val) {
      if (val !== this.activeIndex) {
        this.activeIndex = val;
      }
    },
  },
  mounted() {
    // 监听屏幕宽度和高度变化
    window.addEventListener('resize', this.handleResize, true);
  },
  // 销毁监听
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    getUser(){
      window.location.href = "https://t.me/xincai8888"
    },
    handleCommand(command) {
      console.log(command);
      this.activeIndex = command;
      this.$router.push(command);
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    handleSelect() {},
    goBack() {
      localStorage.setItem('isLogin', false);
      this.$router.push('/home');
    },
  },
};
</script>
